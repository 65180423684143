<template>
  <el-main class="centerwrap">
    <el-card class="box-card">
      <!-- <div slot="header" class="clearfix">
        <h2 class="gobackpage">
         <router-link tag="span" :to="{name:'teamsColumn',params:{tid:tid}}" v-if="tid">
          <i class="iconlujingbeifen1 iconfont"></i>返回团队专栏
        </router-link>
         <router-link tag="span" :to="{name:'columnNew'}" v-else>
          <i class="iconlujingbeifen1 iconfont"></i>返回专栏列表
        </router-link>
      </h2>
      </div> -->
      <div class="centerbox">
        <div class="leftbox">
          <h2 class="titleh2">专栏封面</h2>
          <div class="imgbox">
            <img-upload @change="getImg" class="getimgbtn" :ffixed="true" :ffixedNumber="[1.48,1]"></img-upload>
            <img :src="timageUrl" class="img" v-if="timageUrl" />
          </div>
          <span class="tip">
            <i class="el-icon-upload el-icon--right"></i> 点击上传图片
          </span>
        </div>
        <div class="rightbox">
          <el-form
            ref="editFormRef"
            :model="editForm"
            :rules="editFormRules"
            label-width="100px"
            size="small"
          >
            <el-form-item label="专栏名称：" prop="Special">
              <el-input v-model="editForm.Special"></el-input>
            </el-form-item>
            <el-form-item label="专属链接：" prop="IdentifyIdentify" class="linkbox">
              <el-col :span="12">https://www.beanotes.com/#/s/</el-col>
              <el-col :span="12">
                <el-input v-model="editForm.Identify"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="专栏售价：" required>
              <span
                class="price"
                v-for="item in priceData"
                @click="handelTab(item=='免费' ?'免费' :parseFloat(item),'priceActive')"
                :class="item=='免费'? item==priceActive? 'active' :'': parseFloat(item)==priceActive? 'active' :''"
              >{{item}}</span>
              <el-form-item prop="Price" class="priceitem">
                <el-input
                  placeholder="请输入价格"
                  class="priceinp"
                  @focus="handelTab('','priceActive')"
                  v-model="editForm.Price"
                ></el-input>
                <i>元</i>
              </el-form-item>
            </el-form-item>
            <el-form-item label="专栏折扣：" required>
              <span
                class="price"
                v-for="item in discount"
                @click="handelTab(item,'discountActive')"
                :class="item==discountActive? 'active' :''"
              >{{item =='10' ? '原价':item+'折'}}</span>
            </el-form-item>
            <el-form-item label="专栏概述：" required>
              <div class="changeedit">
                <span @click="handelChangeMavon('editorIndex')">
                  <i class="iconfont iconqiehuan"></i>
                  切换到 {{editorIndex==2? 'tinymce' :'markdown'}}
                </span>
              </div>
              <tinymce-editor
                v-model="editForm.Overview"
                :disabled="disabled"
                ref="editor"
                v-show="editorIndex==1"
              ></tinymce-editor>
              <mavon-editor
                :value="editForm.Markdown"
                ref="meditor"
                v-show="editorIndex==2"
                @input="editForm.Markdown=$event"
              ></mavon-editor>
            </el-form-item>
            <el-form-item class="noborder">
              <el-button
                type="primary"
                size="small"
                v-loading="btnLoading"
                @click="handelEditForm('editFormRef')"
              >保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-card>
  </el-main>
</template>
<script>
import { specialIntroRequest,SpecialIntroRequest } from "@/api/project.js";
import TinymceEditor from "@/components/tinymceEditor";
import MavonEditor from "@/components/mavonEditor";
import { TurndownService } from "@/assets/js/turndown.js";
import ImgUpload from "@/components/Imgupload/imgUpload";
export default {
  components: {
    TinymceEditor,
    MavonEditor,
    ImgUpload
  },
  data() {
    var checkLink = (rule, value, callback) => {
      var re = /^[A-Za-z0-9\-]+$/;
      if (!re.test(value)) {
        callback(new Error("请输入正确的专属链接"));
      }
      callback();
    };
    var checkPrice = (rule, value, callback) => {
      if (value < 0 ) {
        callback(new Error("请给图书定义售价"));
      }
      if (isNaN(value)) {
        callback(new Error("请输入正确的图书售价"));
      }
      callback();
    };
    return {
      btnLoading: false,
      disabled: false,
      editorIndex: "1",
      editForm: {
        Price: 0
      },
      priceActive: "免费",
      priceData: ["免费", "19.9元", "36元", "58元", "66元", "88元", "99元"],
      discount: ["10", "1", "3", "5", "7", "9"],
      discountActive: "10",
      editFormRules: {
        Special: [
          { required: true, message: "请输入图书名称", trigger: "blur" },
          { min: 2, max: 60, message: "长度在 2 到 60 个字符", trigger: "blur" }
        ],
        Identify: [
          { required: true, message: "专属链接不能为空", trigger: "blur" },
          { validator: checkLink, trigger: "blur" }
        ],
        Price: [{ validator: checkPrice, trigger: ["change"] }]
      },
      blob: "",
      timageUrl: ""
    };
  },
  created() {
     if (this.Id) {
      this.getColumninfo(this.Id)
    }
   
  },
  computed: {
    Id() {
      return this.$route.query.Id || "";
    },
    tid() {
      return this.$route.query.tid || "";
    }
  },
  methods: {
    handelTab(index, a) {
      this[a] = index;
    },
    async sentEditForm() {
      try {
        if (this.editorIndex == 2) {
          this.TmacChangeMavon(1);
        }
        var oData = {
          tid: this.tid || 0,
          sid:  this.Id || 0,
          special: this.editForm.Special,
          identify: this.editForm.Identify,
          price: this.editForm.Price,
          discount: this.discountActive,
          overview: this.editForm.Overview,
          cover: this.blob || this.timageUrl,
          editor: this.editorIndex,
          markdown: this.editorIndex === 2 ? this.editForm.Markdown : ""
        };
        var formData = new FormData();
        for (var key in oData) {
          formData.append(key, oData[key]);
        }
        const result = await specialIntroRequest(formData);
        if (result.status == 200) {
          this.$message({
            showClose: true,
            message: result.message,
            type: "success"
          });
          if (result.data && result.data.identify) {
            this.$router.push({
              name: "specialColumnArticle",
              params: {
                Identify: result.data.identify
              }
            });
          }
        } else {
          this.$message({
            showClose: true,
            message: result.message,
            type: "error"
          });
        }
        this.btnLoading = false;
      } catch (err) {}
    },
    handelEditForm(formName) {
      this.$refs[formName].validate(valid => {
        this.btnLoading = true;
        try {
          if (!valid) {
            this.btnLoading = false;
            return;
          }
          this.sentEditForm();
        } catch (err) {
          this.$message({
            showClose: true,
            message: "保存失败",
            type: "error"
          });
          this.btnLoading = false;
          console.log(err);
        }
      });
    },
    TmacChangeMavon(editor, item) {
      if (item) {
        this[item] = editor;
      }
      // editor=2 是  markdown  既markdown 变成 tinymac格式
      // editor=1 是  tinymce   tinymac 变成 markdown
      var _this = this;
      if (editor === 1) {
        // 既markdown 变成 tinymac格式
        var marked = require("marked");
        var rendererMD = new marked.Renderer();
        marked.setOptions({
          renderer: rendererMD,
          gfm: true,
          tables: true,
          breaks: false,
          pedantic: false,
          sanitize: false,
          smartLists: true,
          smartypants: false
        }); //基本设置
        _this.editForm.Overview = marked(_this.editForm.Markdown);
      } else {
        //tinymac 变成 markdown
        var turndownService = new TurndownService(); //Tmac变成Mavon格式
        _this.editForm.Markdown = turndownService.turndown(_this.editForm.Overview);
      }
    },
    handelChangeMavon(Editor) {
      var _this = this;
      this.$confirm("切换编辑器后，部分样式可能会丢失, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          _this.TmacChangeMavon(_this[Editor] === 2 ? 1 : 2, Editor);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消切换"
          });
        });
    },
    getImg(url, data) {
      this.timageUrl = url;
      this.blob = data;
      console.log(data);
    },
    async getColumninfo(id) {
      try {
        const result = await SpecialIntroRequest({
          sid: id
        });
        if (result.status === 200) {
          var intorData = result.data.intro;
          this.editForm = intorData;
          this.timageUrl = intorData.Cover;
          this.Discount = intorData.Discount;
          this.priceActive=intorData.Price ||'免费'
          this.editorIndex = intorData.Editor || 1;
        }
      } catch (e) {}
    }
  },
  watch: {
    priceActive(priceActive) {
      if (priceActive == "免费") {
        this.editForm.Price = 0;
      } else if (priceActive == "") {
        this.editForm.Price = "";
      } else {
        this.editForm.Price = parseFloat(priceActive);
      }
    },
    $route(newName, oldName) {
      if (!newName.query.Id) {
        this.editForm.Bookname = "";
        this.timageUrl = "";
        this.editForm.priceActive = "免费";
        this.Discount = "10";
        this.editForm.Overview = "";
        this.editForm.Markdown = "";
        this.editorIndex = 1;
      }
    }
  }
};
</script>
<style  lang="less" scoped>
.textbox{
    /deep/p{
    margin-top: 1em;
    margin-bottom: 1em;
  }
  /deep/ul,  /deep/li  {
  list-style: inherit;
}
/deep/h1, h2, h3, h4, h5, h6 {
  font-weight: inherit;
}
/deep/em, /deep/i {
font-style:italic!important;
}
/deep/ol{
  display: block;
      list-style: decimal;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px
}
}
.centerwrap {
  .titleh2 {
  }
}
.box-card{
  width:1200px;
  margin:30px auto;
}
.centerbox {
  display: flex;
  .leftbox {
    width: 300px;
    box-sizing: border-box;
    padding: 0 50px;
    margin: 30px auto;
    border-right: 1px solid #ccc;
    .imgbox {
      width: 188px;
      height: 127px;
      overflow: hidden;
      margin-bottom: 30px;
      position: relative;
      .getimgbtn {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 5;
        /deep/.el-upload-dragger {
          width: 188px;
          height: 127px;
          line-height: 127px;
        }
      }
      img {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1;
      }
    }
    .titleh2 {
      font-size: 16px;
      margin-bottom: 30px;
    }
  }
  .rightbox {
    flex: 1;
    margin: 30px 50px;
    .price {
      padding: 0 10px;
      color: #7368ba;
      font-size: 12px;
      line-height: 26px;
      height: 26px;
      white-space: nowrap;
      vertical-align: middle;
      background: #fff;
      border: 1px solid #7368ba;
      text-align: center;
      margin-right: 10px;
      border-radius: 4px;
      display: inline-block;
      cursor: pointer;
      &.active {
        color: #fff;
        background: #7368ba;
      }
    }
    .priceinp {
      width: 120px;
      /deep/.el-input__inner {
        padding-right: 25px !important;
      }
    }
    .priceradio {
      /deep/.el-radio__input {
        cursor: pointer;
        vertical-align: middle;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
      }
      .el-radio {
        line-height: 26px;
        height: 26px;
        white-space: nowrap;
        vertical-align: middle;
        background: #fff;
        border: 1px solid #7368ba;
        text-align: center;
        margin-right: 10px;
        border-radius: 4px;
      }
      /deep/.el-radio__label {
        padding: 0 10px;
        color: #7368ba;
        display: block;
        font-size: 12px;
        font-weight: normal;
      }
      /deep/.el-radio__input.is-checked + .el-radio__label {
        color: #fff;
        background: #7368ba;
      }
    }
  }
}
 .gobackpage {

    font-size: 16px;
    color: #000000;
    line-height: 20px;
    cursor: pointer;
    i {
      margin-right: 10px;
    }
  }
.changeedit {
  cursor: pointer;
  text-align: right;
  float: right;
  margin-top: -35px;
  i {
    color: #11adcf;
  }
  span {
    display: inline-block;
  }
}
.linkbox {
  /deep/.el-form-item__error {
    left: 41.66667%;
  }
}
.priceitem {
  display: inline-block;
  margin-bottom: 0;

  i {
    position: absolute;
    right: 7px;
    color: #888;
  }
}
.tip {
  font-size: 13px;
  color: #111;
}
</style>